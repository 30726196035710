export default {
    created() {
        this.loadScript();
    },
    data() {
        return {
            isScriptLoaded: false,
            script: null
        }
    },
    methods: {
        async pay(payload) {
            return new Promise((resolve, reject) => {
                var handler = window.PaystackPop.setup({
                    key: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY,
                    email: payload.customer_email,
                    amount: payload.amount * 100,
                    currency: payload.currency || "NGN",
                    metadata: {
                        custom_fields: [
                            {
                                display_name: "Wallet ID",
                                variable_name: "wallet_id",
                                value: payload.wallet_id
                            },
                            {
                                display_name: "User ID",
                                variable_name: "user_id",
                                value: payload.user_id
                            }
                        ]
                    },
                    callback: function (response) {
                        if (response.status !== 'success') {
                            return reject(response);
                        }

                        resolve(response);
                    },
                    onClose: function () {
                        resolve();
                    }
                });

                handler.openIframe();
            });
        },
        async loadScript() {
            if (this.scriptLoaded()) {
                return;
            }

            this.script = document.createElement('script');

            this.script.onload = () => {
                this.isScriptLoaded = true;
            };

            this.script.async = true;

            this.script.setAttribute('src', process.env.VUE_APP_PAYSTACK_INLINE_URL);

            document.head.appendChild(this.script);
        },
        scriptLoaded() {
            return this.isScriptLoaded ||
                document.head.querySelectorAll(`script[src="${process.env.VUE_APP_PAYSTACK_INLINE_URL}"]`).length > 0;
        }
    }
}